import './App.css'
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./pages/contact";
import Coins from "./pages/coins"
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import AddCoin from "./pages/addcoin"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Coins />} />
            <Route path="coins" element={<Coins />} />
            <Route path="addcoin" element={<AddCoin />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div >
  )
}

export default App;
