import '../App.css'
import { useEffect, useState } from "react"
import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Coins() {
  const [coins, setCoins] = useState([])
  const [loading, setLoading] = useState(false)
  const [nation, setNation] = useState("")

  const nationList = [
    {
      Code: "All",
      Nation: "All",
    },
    {
      Code: "Armenia",
      Nation: "Armenia",
    },
    {
      Code: "Australia",
      Nation: "Australia",
    },
    {
      Code: "Austria",
      Nation: "Austria",
    },
    {
      Code: "Barbados",
      Nation: "Barbados",
    },
    {
      Code: "Cambodia",
      Nation: "Cambodia",
    },
    {
      Code: "Canada",
      Nation: "Canada",
    },
    {
      Code: "Cayman Island",
      Nation: "Cayman Island",
    },
    {
      Code: "Chad",
      Nation: "Chad",
    },
    {
      Code: "China",
      Nation: "China",
    },
    {
      Code: "Congo",
      Nation: "Congo",
    },
    {
      Code: "Cook Islands	",
      Nation: "Cook Islands	",
    },
    {
      Code: "Fiji",
      Nation: "Fiji",
    },
    {
      Code: "Ghana",
      Nation: "Ghana",
    },
    {
      Code: "Gibraltar",
      Nation: "Gibraltar",
    },
    {
      Code: "Laos",
      Nation: "Laos",
    },
    {
      Code: "Mexico",
      Nation: "Mexico",
    },
    {
      Code: "New Zealand",
      Nation: "New Zealand",
    },
    {
      Code: "Niue",
      Nation: "Niue",
    },
    {
      Code: "Russia",
      Nation: "Russia",
    },
    {
      Code: "Rwanda",
      Nation: "Rwanda",
    },
    {
      Code: "Samoa",
      Nation: "Samoa",
    },
    {
      Code: "Serbia",
      Nation: "Serbia",
    },
    {
      Code: "Somalia",
      Nation: "Somalia",
    },
    {
      Code: "South Africa",
      Nation: "South Africa",
    },
    {
      Code: "South Korea",
      Nation: "South Korea",
    },
    {
      Code: "St. Helena	",
      Nation: "St. Helena	",
    },
    {
      Code: "Ukraine",
      Nation: "Ukraine",
    },
    {
      Code: "GBR",
      Nation: "United Kingdom",
    },
    {
      Code: "United States",
      Nation: "United States",
    },
  ];

  const nationListItem = nationList.map((element) => {
    return <MenuItem key={element.Code} value={element.Code}>{element.Nation}</MenuItem>
  })

  useEffect(() => {
    setLoading(true)
  }, [])

  const handleChange = (event) => {

    setNation(event.target.value);
    if (event.target.value !== "All"){
      fetch(process.env.REACT_APP_BACKEND_URL + `/coins?nation=` + event.target.value, { method: "GET", headers: { 'Content-Type': 'x-www-form-urlencoded' } })
      .then(response => response.json())
      .then(json => setCoins(json))
      .finally(() => {
        setLoading(false)
      });
    }
    else{
      fetch(process.env.REACT_APP_BACKEND_URL + `/coins_all`, { method: "GET", headers: { 'Content-Type': 'x-www-form-urlencoded' } })
      .then(response => response.json())
      .then(json => setCoins(json))
      .finally(() => {
        setLoading(false)
      });
    }

  };

  return (

    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Nation</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nation}
                label="nation"
                onChange={handleChange}
              >
                {nationListItem}
              </Select>

            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} md={9} lg={9}>
          {loading ? (
            <Paper sx={{ p: 2 }} >Select nation</Paper>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow key="header">
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Year</TableCell>
                    <TableCell align="left">Series</TableCell>
                    <TableCell align="left">Mintage</TableCell>
                    <TableCell align="left">Nation</TableCell>
                    <TableCell align="left">Diameter</TableCell>
                    <TableCell align="left">Weight</TableCell>
                    <TableCell align="left">Denomination</TableCell>
                    <TableCell align="left">Mint</TableCell>
                    <TableCell align="left">Purity</TableCell>
                    <TableCell align="left">Grade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coins.map(coin => (
                    <TableRow
                      key={coin.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{coin.name}</TableCell>
                      <TableCell align="left">{coin.year}</TableCell>
                      <TableCell align="left">{coin.series}</TableCell>
                      <TableCell align="left">{coin.mintage}</TableCell>
                      <TableCell align="left">{coin.nation}</TableCell>
                      <TableCell align="left">{coin.diameter}</TableCell>
                      <TableCell align="left">{coin.weight}</TableCell>
                      <TableCell align="left">{coin.denomination}</TableCell>
                      <TableCell align="left">{coin.mint}</TableCell>
                      <TableCell align="left">{coin.purity}</TableCell>
                      <TableCell align="left">{coin.grade}</TableCell>

                      {/*
                              <TableCell align="right"><Box
                                component="img"
                                sx={{
                                  height: 200,
                                  width: 200,
                                  maxHeight: { xs: 300, md: 300 },
                                  maxWidth: { xs: 300, md: 300 },
                                }}
                                alt="The house from the offer."
                                src={"https://candia.s3.amazonaws.com/coins/" + coin.photo_reverse}
                              /></TableCell>
                              <TableCell align="center"><Box
                                component="img"
                                sx={{
                                  height: 200,
                                  width: 200,
                                  maxHeight: { xs: 300, md: 300 },
                                  maxWidth: { xs: 300, md: 300 },
                                }}
                                alt="The house from the offer."
                                src={"https://candia.s3.amazonaws.com/coins/" + coin.photo_obverse}
                              /></TableCell>
                              */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Coins;
