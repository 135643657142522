import '../App.css'
import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


function AddCoin() {
  return (

    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            Test
          </Paper>
        </Grid>

       
      </Grid>
    </Container>
  )
}

export default AddCoin;
